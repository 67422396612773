
export const nullDate = new Date("1970-01-01T00:00:00+00:00");

const _baseUrl = "/api/v1";

export const baseUrl = _baseUrl;
export const recaptchaToken = "6LeYuGolAAAAAH0KHrUuIdwpg1sb_yfHlFk93u3m";

export const competitionVersion = 2024;
export const competitonLive = true;
export const archived = false;
export const discordLink = "https://discord.gg/BZqZ7zqC6T";
export const pricesList = [];
