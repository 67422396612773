import {inject, observer} from "mobx-react";
import {RouterStore} from "mobx-react-router";
import * as React from "react";

import {IRootStore} from "@store/index";

import Footer from "@components/Footer";
import Timer from "@components/Timer";

import TrailOfBitsLogo from "../assets/images/sponsors/trail_of_bits_logo.svg";
import OtterSecLogo from "../assets/images/sponsors/ottersec_logo.svg";
import HexRaysLogo from "../assets/images/sponsors/hexrays_logo.png";
import SecForceLogo from "../assets/images/sponsors/secforce_logo.svg";
import IntigritiLogo from "../assets/images/sponsors/intigriti_logo.png";
import BurpSuiteLogo from "../assets/images/sponsors/burpsuite_logo.svg";

import {competitionVersion, competitonLive, discordLink, pricesList} from "@consts/index";
import {formatToCustomJctf} from "@libs/date";
import "@styles/homepage.scss";

@inject("routing", "store")
@observer
export class HomePage extends React.Component<IHomePageProps, {}> {
    public async componentDidMount( ) {
        await this.props.store.ctf.fetchInfo( );
    }

    public render( ) {
        const { store } = this.props;
        const now = new Date();

        return (
            <div className={"page homepage"}>
                <div className={"bg"}>
                    <span/>
                    <span/>
                </div>

                <div className={"inner"}>
                    <section className={"sec1"}>
                        <div className={"logo"}>
                            just
                            <span>ctf</span>
                            {competitionVersion}
                            <sup>[teaser]</sup>
                        </div>
                        <div className={"text"}>
                            Capture The <span className={"flag"} /> Competition
                        </div>

                        <div className={"stats"}>
                            {competitonLive && !store.ctf.info.isStarted() && (
                                <>
                                    <div className={"stat"}>
                                        <div>{store.ctf.info.teams_count}</div>
                                        <p>Teams</p>
                                    </div>

                                    <div className={"stat"}>
                                        <div>{store.ctf.info.countries_count}</div>
                                        <p>Countries</p>
                                    </div>
                                </>
                            )}
                            {competitonLive && store.ctf.info.isStarted() && (
                                <>
                                    <div className={"stat"}>
                                        <div>{store.ctf.info.flags_count}</div>
                                        <p>Flags submitted</p>
                                    </div>

                                    <div className={"stat"}>
                                        <div>{store.ctf.info.teams_count}</div>
                                        <p>Teams registered</p>
                                    </div>

                                    <div className={"stat"}>
                                        <div>{store.ctf.info.tasks_unsolved_count}</div>
                                        <p>Unsolved challenges</p>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className={"timer"}>
                            {!store.ctf.info.isStarted() && (
                              <>
                                  <h4>Starts in</h4>
                                  <Timer date={store.ctf.info.start}/>
                              </>
                            )}
                            {store.ctf.info.isRunning() && (
                              <>
                                  <h4>Ends in</h4>
                                  <Timer date={store.ctf.info.end}/>
                              </>
                            )}
                            {store.ctf.info.isEnded() && (
                              <>
                                  <h4>CTF is over!</h4>
                                  <Timer date={store.ctf.info.end}/>
                              </>
                            )}
                        </div>

                        {competitonLive && !store.ctf.isLoggedIn && <a href={"/register"} className={"register"} onClick={this.redirectToRegister}>Register</a>}
                    </section>

                    <section className={"sec3"}>
                        <h1 className={"mainTitle"}>Info</h1>

                        <ul>
                            <li>
                                <h4>Start:</h4>
                                <p>{store.ctf.info.start && formatToCustomJctf(store.ctf.info.start)}</p>
                            </li>
                            <li>
                                <h4>Time:</h4>
                                <p>{store.ctf.info.diffHours()}h</p>
                            </li>
                            <li>
                                <h4>Format:</h4>
                                <p>jeopardy on-line</p>
                            </li>

                            {discordLink &&
                                <li>
                                    <h4>Join our <a href={discordLink} title={"Discord"} target={"_blank"}>Discord</a></h4>
                                </li>
                            }
                        </ul>
                    </section>

                    <section className={"sec7"}>
                        <p>
                            justCTF {competitionVersion} [teaser] is an online jeopardy-style <a href={"https://ctftime.org/ctf-wtf/"} rel={"noreferrer noopener"} target={"_blank"}>[capture the flag]</a> competition organized as a teaser for the open <a href={"https://hackyeah.pl/justctf/"}>justCTF 2024 Finals</a> event happening on the 28th of September, 2024 at <a href={"https://hackyeah.pl/"}>HackYeah</a> in Kraków, Poland.
                        </p>

                        <p>Check out our previous editions:</p>
                        <ul>
                            <li><a href={"https://github.com/justcatthefish/justctf-2019"} rel={"noreferrer noopener"} target={"_blank"}>justCTF 2019</a></li>
                            <li><a href={"https://github.com/justcatthefish/justctf-2020"} rel={"noreferrer noopener"} target={"_blank"}>justCTF [*] 2020</a></li>
                            <li><a href={"https://github.com/justcatthefish/justctf-2022"} rel={"noreferrer noopener"} target={"_blank"}>justCTF 2022</a></li>
                            <li><a href={"https://github.com/justcatthefish/justctf-2023"} rel={"noreferrer noopener"} target={"_blank"}>justCTF 2023</a></li>
                        </ul>
                    </section>

                    <section className={"sec4"}>
                        <h1 className={"mainTitle"}>Prizes</h1>

                        <p>Reimbursement of travel expenses to the finals for top N teams (N&gt;=3) x 4 people</p>
                        <p>Top3 teams: 1x IDA Pro named license for a year with 2 decompilers</p>
                        <p>Top6 teams: 1x Burp Suite Professional license for a year</p>
                    </section>

                    <section className={"sec5"}>
                        <h1 className={"mainTitle"}>Sponsors</h1>

                        <ul>
                            <li className={"tob"}><a href={"https://cutt.ly/veucZatb"} rel={"noreferrer noopener"} target={"_blank"}><img src={TrailOfBitsLogo} alt={"Trail of Bits"} /></a></li>
                            <li className={"osec"}><a href={"https://cutt.ly/leucL7cz"} rel={"noreferrer noopener"} target={"_blank"}><img src={OtterSecLogo} alt={"OtterSec"} /></a></li>
                        </ul>

                        <ul>
                            <li className={"hr"}><a href={"https://cutt.ly/UeoKTv2m"} rel={"noreferrer noopener"} target={"_blank"}><img src={HexRaysLogo} alt={"Hex-Rays"} /></a></li>
                            <li className={"bs"}><a href={"https://cutt.ly/yeptWZ1E"} rel={"noreferrer noopener"} target={"_blank"}><img src={BurpSuiteLogo} alt={"Burp Suite"} /></a></li>
                            
                        </ul>

                        <ul>
                            <li className={"in"}><a href={"https://cutt.ly/TepyLQby"} rel={"noreferrer noopener"} target={"_blank"}><img src={IntigritiLogo} alt={"Intigriti"} /></a></li>
                            <li className={"sf"}><a href={"https://cutt.ly/5eoKRyNL"} rel={"noreferrer noopener"} target={"_blank"}><img src={SecForceLogo} alt={"Secforce"} /></a></li>
                        </ul>
                    </section>

                    <section className={"sec6"}>
                        <ul>
                            <li className={"email"}><a href={"mailto:justcatthefish@gmail.com"} title={""}>justcatthefish@gmail.com</a></li>
                            <li className={"twitter"}><a href={"https://twitter.com/justcatthefish"} title={""} target={"_blank"}>@justcatthefish</a></li>
                        </ul>
                    </section>

                    <Footer />
                </div>
            </div>
        );
    }

    public redirectToRegister = ( e: React.MouseEvent<HTMLAnchorElement> ) => {
        e.preventDefault();

        const href = e.currentTarget.attributes.getNamedItem("href");

        if ( !!href && !!this.props.routing ) {
            this.props.routing.push(href.value);
        }
    }
}

interface IHomePageProps {
    routing: RouterStore;
    store: IRootStore;
}
